












import { defineComponent, PropType } from "@vue/composition-api";
import { Receipt } from "@/types/typeDefinitions";

export default defineComponent({
    name: "Receipt",
    props: {
        receipts: Array as PropType<Receipt[]>
    },
    setup() {
        const resendReceipt = () => {
            //ToDo: Send to server for Email/ Send from here?
            console.log("Resend Receipt")
        }

        return {
            resendReceipt
        }
    }
})
