export function usePicture() {
    const getPicturePath = (productID: string): string => {
        switch (productID) {
            case 'com.classplash.babyComposer':
                return 'babyComposer.png'
            case 'com.classplash.corneliuscomposer':
                return 'corneliusComposer.png'
            case 'com.classplash.flutemaster':
                return 'fluteMaster.png'
            case 'com.classplash.hellomusic':
                return 'helloMusic.png'
            case 'com.classplash.rhythmicvillage':
                return 'rhythmicVillage.png'
            case 'com.classplash.harmonycity':
                return 'harmonyCity.png'
            case 'com.classplash.worldofmusic':
                return 'worldOfMusic/worldOfMusicLogo.png'
            case 'com.classplash.themagicflute':
                return 'theMagicFlute/tmf-icon-M-round.webp'
            default:
                return 'planet.webp'
        }
    }

    return {
        getPicturePath
    }
}