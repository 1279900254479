




















































import { computed, defineComponent, PropType } from "@vue/composition-api";
import Receipt from "@/components/Modal/Receipt.vue";
import { DateOptions, LicenseListItem } from "@/types/typeDefinitions";
import { translate } from "@/plugins/multi-language";
import LicenseLoginData from "@/components/LicenseLoginData.vue";
import { useLicense } from "@/api_connectors/license";
import { useToast } from "@/composables/useToast";
import { useModal } from "@/composables/useModal";

export default defineComponent({
    name: "LicenseDetails",
    components: { LicenseLoginData, Receipt },
    props: {
        license: {
            type: Object as PropType<LicenseListItem>,
            required: true
        }
    },
    setup(props) {
        const { sendSubscriptionLinkRequest } = useLicense()
        const { toastController } = useToast()
        const { modalController } = useModal()

        const purchaseDate = computed(() => {
            return new Date(props.license.purchaseDate).toLocaleDateString(undefined, DateOptions)
        })

        const expirationDate = computed(() => {
            if (props.license.expirationDate !== null) return new Date(props.license.expirationDate).toLocaleDateString(undefined, DateOptions)
            return translate('license.lifeTime').toString();
        })

        const sendSubscriptionLink = async (licenseId: string) => {
            if (!licenseId) return toastController.toast(translate('toast.notSent').toString(), {
                title: translate('toast.noIdFound').toString(),
                autoHideDelay: 3000,
                variant: 'danger',
                solid: true,
                toaster: 'b-toaster-top-center',
            });
            const response = await sendSubscriptionLinkRequest(licenseId)

            if (!response) return toastController.toast(
                translate('toast.failedRequest',
                    {
                        requestGoal: translate('request.sendManagementLink').toString(),
                        addInfo: translate('addInfo.contactSupport').toString()
                    }
                ).toString(),
                {
                    title: translate('toast.error').toString(),
                    autoHideDelay: 10000,
                    variant: 'danger',
                    solid: true,
                    toaster: 'b-toaster-top-center',
                }
            );

            modalController?.msgBoxOk(translate('modal.shopifySent').toString(), {
                okVariant: 'classplash-variant-ok'
            })
        }

        return {
            purchaseDate,
            expirationDate,
            sendSubscriptionLink
        }
    }
})
