















































import { defineComponent, onMounted, reactive } from "@vue/composition-api";
import { useLicense } from "@/api_connectors/license";
import { DateOptions, LicenseListItem } from "@/types/typeDefinitions";
import LogoutButton from "@/components/LogoutButton.vue";
import LicenseDetails from "@/components/Modal/LicenseDetails.vue";
import { translate } from "@/plugins/multi-language";
import LicenseCard from "@/components/LicenseCard.vue";
import VoucherLicenseCard from "@/components/VoucherLicenseCard.vue";
import { useEmptyObject } from "@/types/newEmptyObject";
import LicenseBanner from "@/components/LicenseBanner.vue";
import { usePicture } from "@/composables/usePicture";

export default defineComponent({
    name: "License",
    components: { LicenseBanner, VoucherLicenseCard, LicenseCard, LicenseDetails, LogoutButton },
    setup() {
        interface LicenseList {
            id: string;
            name?: string;
            purchaseDate: string;
            expirationDate?: string;
            autoRenew?: boolean;
            method: string;
            features: string;
            showLicense: { productID: string; variantID: string } | null;
            link?: string;
            picturePath: string | null;
        }

        const { getLicenses } = useLicense()
        const { getPicturePath } = usePicture()
        const { newLicenseListItem } = useEmptyObject()

        const items = reactive<LicenseListItem[]>([])
        const tableItems = reactive<LicenseList[]>([])
        const expiredItems = reactive<LicenseList[]>([])
        const selectedLicense = reactive<LicenseListItem>(newLicenseListItem())

        const fields = [
            { key: 'name', label: translate('license.name') },
            { key: 'expiryDate', label: translate('license.expire') },
            { key: 'method', label: translate('license.method') },
            { key: 'features', label: translate('license.feature') },
            { key: 'showLicense', label: '' }
        ]

        const update = async () => {
            Object.assign(items, await getLicenses())
            // console.log(items)
            for (const item of items) {
                const formatedDatePurch = new Date(item.purchaseDate).toLocaleDateString(undefined, DateOptions)
                let formatedDateExp
                if (item.expirationDate) {
                    formatedDateExp = new Date(item.expirationDate).toLocaleDateString(undefined, DateOptions)
                } else {
                    formatedDateExp = 'lifeTime'
                }
                if (item.isActive) {
                    tableItems.push({
                        id: item.productId,
                        name: item.name,
                        purchaseDate: formatedDatePurch,
                        expirationDate: formatedDateExp,
                        autoRenew: item.autoRenew,
                        method: item.type,
                        features: '',
                        showLicense: { productID: item.productId, variantID: item.variantId },
                        picturePath: getPicturePath(item.productId)
                    })
                    continue;
                }
                expiredItems.push({
                    id: item.productId,
                    name: item.name,
                    purchaseDate: formatedDatePurch,
                    expirationDate: formatedDateExp,
                    method: item.type,
                    features: '',
                    showLicense: { productID: item.productId, variantID: item.variantId },
                    link: item.link,
                    picturePath: getPicturePath(item.productId)
                })
            }
        }

        const openDetails = async (licenseIdentifier: { productID: string; variantID: string }, openModal: Function) => {
            if (!(licenseIdentifier.productID && licenseIdentifier.variantID)) return;

            const tempLicense = items.find(license => {
                return (license.productId === licenseIdentifier.productID && license.variantId === licenseIdentifier.variantID)
            })

            if (!tempLicense) return;
            tempLicense.picturePath = getPicturePath(tempLicense.productId)
            Object.assign(selectedLicense, tempLicense);

            await openModal()
        }

        onMounted(() => update())

        return {
            tableItems,
            expiredItems,
            selectedLicense,
            fields,
            openDetails
        }
    }
})
