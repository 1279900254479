













































import { defineComponent, PropType } from "@vue/composition-api";

export default defineComponent({
    name: "LicenseCard",
    props: {
        pictureName: String,
        title: String,
        expirationDate: String,
        paymentMethod: String,
        boughtFeatures: String,
        autoRenew: Boolean,
        product: Object as PropType<{ productID: string; variantID: string }>,
        expired: Boolean,
        link: String
    },
    setup(_, context) {
        const openDetails = async (product: { productID: string; variantID: string }) => {
            if (!product.productID || !product.variantID) return;

            await context.emit('change-license-id', product);
        }

        return {
            openDetails
        }
    }
})
